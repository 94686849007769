import { apiUrl } from "./config";

const getProjectMembers = async () => {
  try {
    const token = localStorage.getItem("accessToken");

    const response = await fetch(apiUrl + "/project/members", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.errors);
    }
  } catch (error) {
    throw new Error(error);
  }
};

const getMyProjects = async () => {
  try {
    const token = localStorage.getItem("accessToken");

    const response = await fetch(apiUrl + "/my-projects", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.errors);
    }
  } catch (error) {
    throw new Error(error);
  }
};

const getProjects = async () => {
  try {
    const token = localStorage.getItem("accessToken");

    const response = await fetch(apiUrl + "/projects", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.errors);
    }
  } catch (error) {
    throw new Error(error);
  }
};

const getProjectInfo = async () => {
  try {
    const token = localStorage.getItem("accessToken");

    const response = await fetch(apiUrl + "/project-info", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.errors);
    }
  } catch (error) {
    throw new Error(error);
  }
};

const switchProject = async (project_id) => {
  try {
    const token = localStorage.getItem("accessToken");

    const data = {
      project_id: project_id,
    };

    const response = await fetch(apiUrl + "/switch-project", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    return error;
  }
};
const saveProject = async (project) => {
  try {
    const token = localStorage.getItem("accessToken");

    const response = await fetch(apiUrl + "/projects", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: project, // FormData is passed directly here
    });

    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.errors);
    }
  } catch (error) {
    return error;
  }
};

const updateProjectInfo = async (project) => {
  try {
    const token = localStorage.getItem("accessToken");

    // Ensure the endpoint includes the project ID for the update
    const response = await fetch(`${apiUrl}/project/${project.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(project), // Send the project data as JSON
    });

    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.errors || 'Failed to update project');
    }
  } catch (error) {
    throw new Error(error.message || 'Failed to update project');
  }
};


const updateProjectImage = async (file, current_project_id) => {
  try {
    const token = localStorage.getItem("accessToken");

    if (file.size > 2 * 1024 * 1024) {
      window.alert("File size exceeds 2MB limit.");
      return;
    }

    const formData = new FormData();
    formData.append("image", file);
    formData.append("project_id", current_project_id);

    const response = await fetch(apiUrl + "/projects/image", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
      },
      body: formData,
    });

    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.errors);
    }
  } catch (error) {
    throw new Error(error);
  }
};

const updateProjectStatus = async (project_id, { status, notes }) => {
  try {
    const token = localStorage.getItem("accessToken");

    // Prepare the data object with both status and notes
    const data = {
      status: String(status), // Ensure status is a string
      notes: notes,            // Pass the notes
    };

    const response = await fetch(`${apiUrl}/projects/${project_id}/status`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data), // Send both status and notes as JSON
    });

    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.errors);
    }
  } catch (error) {
    throw new Error(error.message || 'Failed to update project');
  }
};


export {
  getProjectMembers,
  getMyProjects,
  saveProject,
  getProjectInfo,
  switchProject,
  updateProjectInfo,
  getProjects,
  updateProjectImage,
  updateProjectStatus,
};
