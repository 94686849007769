import React, { useEffect, useState } from 'react';
import { getMyProjects, switchProject, updateProjectStatus } from '../api/project';
import { Line } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProjectTable from '../components/ProjectTable'; // Import without curly braces

function Projects() {
    const [myProjects, setMyProjects] = useState([]);
    const [expandedProject, setExpandedProject] = useState(null); // To track which project is expanded

    useEffect(() => {
        const fetchMyProjects = async () => {
            try {
                const fetchedMyProjects = await getMyProjects();
                setMyProjects(fetchedMyProjects);
                console.log(fetchedMyProjects);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchMyProjects();
    }, []);

    const handleSwitchProject = async (project_id) => {
        try {
            await switchProject(project_id);
            window.location.href = "/";
        } catch (error) {
            console.error('Error switching project:', error);
        }
    };

    const handleUpdate = async (project_id, updatedStatus, updatedNote) => {
        try {
            const statusString = String(updatedStatus);
            await updateProjectStatus(project_id, { status: statusString, notes: updatedNote });
            setMyProjects(myProjects.map(project =>
                project.id === project_id
                    ? { ...project, status: statusString, notes: updatedNote }
                    : project
            ));
            toast.success('Project updated successfully');
        } catch (error) {
            toast.error('Error updating project');
            console.error('Error updating project:', error);
        }
    };

    const handleStatusChange = (project_id, newStatus) => {
        setMyProjects(myProjects.map(project =>
            project.id === project_id ? { ...project, status: newStatus } : project
        ));
    };

    const handleNoteChange = (project_id, newNote) => {
        setMyProjects(myProjects.map(project =>
            project.id === project_id ? { ...project, notes: newNote } : project
        ));
    };

    const toggleExpand = (project_id) => {
        setExpandedProject(expandedProject === project_id ? null : project_id);
    };

    const finishedProjectsCount = myProjects.filter(project => project.status === 'מסירה').length;
    const ongoingProjectsCount = myProjects.filter(project => project.status === 'בעבודה').length;
    const waitingProjectsCount = myProjects.filter(project => project.status === 'פרוייקט חדש').length;

    const lineChartData = {
        labels: ['ינואר', 'פרבואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'],
        datasets: [
            {
                label: 'פרויקטים שהסתיימו',
                data: [3, 2, 2, 5, 4, 6, 8, 7, 10, 9, 12, 15],
                fill: true,
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                tension: 0.4,
            },
        ],
    };

    const options = {
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
        },
    };

    return (
        <div>
            <div className='container'>
                <h2 className='bold ps-2'>תיקים שלי</h2>
                <p className='text-muted mt-2 fw-500 ps-2 mb-5'>תיקים קיימים</p>

                <div className="row mb-4">
                    <div className="col-md-4">
                        <div className="bg-white rounded p-3 shadow-sm">
                            <h5>תיקים שהסתיימו</h5>
                            <h6>{finishedProjectsCount}</h6>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="bg-white rounded p-3 shadow-sm">
                            <h5>תיקים  בעבודה</h5>
                            <h6>{ongoingProjectsCount}</h6>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="bg-white rounded p-3 shadow-sm">
                            <h5>תיקים שממתינים למסירה</h5>
                            <h6>{waitingProjectsCount}</h6>
                        </div>
                    </div>
                </div>



                {/* Existing table with Bootstrap Zebra Striping */}
                <div className="table-responsive bg-white rounded p-3 shadow-sm mt-5">
                    <h5 className='mb-3'>טבלת פרוייקטים</h5>
                    <table className="table table-striped table-bordered w-100">  {/* Ensure full width */}
                        <thead>
                            <tr>
                                <th >שם הפרוייקט</th>
                                <th >סטטוס</th>
                                <th >הערות</th>
                                <th >עדכון</th>
                            </tr>
                        </thead>
                        <tbody>
                            {myProjects.map((project) => (
                                <tr key={project.id}>
                                    <td>{project.name}</td>
                                    <td>
                                        <select
                                            className="form-select"
                                            value={project.status}
                                            onChange={(e) => handleStatusChange(project.id, e.target.value)}
                                        >
                                            <option value="פרוייקט חדש">פרוייקט חדש</option>
                                            <option value="התחיל תיקון">התחיל תיקון</option>
                                            <option value="תוקן ושולם למוסך">תוקן ושולם למוסך</option>
                                            <option value="תוקן חתום">תוקן חתום</option>
                                            <option value="סיים וטרם">סיים וטרם</option>
                                            <option value="רכב חברה">רכב חברה</option>
                                            <option value="מסירה">מסירה</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={project.notes || ""}
                                            onChange={(e) => handleNoteChange(project.id, e.target.value)}
                                            placeholder="הכנס הערה"
                                        />
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => handleUpdate(project.id, project.status, project.notes || '')}
                                        >
                                            עדכן
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
              </div>

              
             {/* Insert ProjectTable here */}
             <ProjectTable />
            </div>
        </div>
    );
}

export default Projects;
