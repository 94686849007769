import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { getProjects, updateProjectInfo } from '../api/project';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ProjectTable() {
    const columns = [
        { label: 'מזהה', key: 'id', inputType: 'text', hidden: true }, // Set hidden flag
        { label: 'מספר תיק', key: 'name', inputType: 'text' },
        { label: 'סטטוס', key: 'status', inputType: 'select', options: ['חדש', 'בעבודה', 'מסירה'] },
        { label: 'גורם מפנה', key: 'referring_party', inputType: 'text' },
        { label: 'מספר רישוי אוטובוס', key: 'bus_license_number', inputType: 'text' },
        { label: 'תאריך תאונה', key: 'accident_date', inputType: 'date' },
        { label: 'מספר רישוי צד ג', key: 'third_party_license_number', inputType: 'text' },
        { label: 'שם ומספר טלפון צד ג', key: 'third_party_name_and_phone', inputType: 'text' },
        { label: 'שיחה ראשונית', key: 'initial_call_with_third_party', inputType: 'select', options: ['כן', 'לא'] },
        { label: 'תיאור אירוע', key: 'event_description', inputType: 'textarea' },
        { label: 'אזור בארץ', key: 'region', inputType: 'text' },
        { label: 'העלאת תמונות', key: 'damage_photo_with_case_number', inputType: 'file' },
        { label: 'Actions', key: 'actions' },
    ];

    const [projects, setProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        fetchProjects();
    }, []);

    const fetchProjects = async () => {
        try {
            const fetchedProjects = await getProjects();
            setProjects(fetchedProjects);
            setFilteredProjects(fetchedProjects);
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };

    useEffect(() => {
        const results = projects.filter(item =>
            Object.values(item).some(
                value => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setFilteredProjects(results);
    }, [searchTerm, projects]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleInputChange = (projectId, key, value) => {
        setProjects(prevProjects =>
            prevProjects.map(project =>
                project.id === projectId ? { ...project, [key]: value } : project
            )
        );
    };

    const handleSave = async (project) => {
        try {
            await updateProjectInfo(project);
            toast.success('Project updated successfully');
            fetchProjects(); // Refresh the projects list
        } catch (error) {
            toast.error('Error updating project');
            console.error('Error updating project:', error);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredProjects.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredProjects.length / itemsPerPage);

    return (
        <div className='bg-white rounded p-3 shadow-sm mt-5'>
            <div>
                <input 
                    type="text" 
                    placeholder="חיפוש" 
                    value={searchTerm} 
                    onChange={handleSearch} 
                    className="form-control my-3" 
                />
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        {columns.map(col => (
                            !col.hidden && <th key={col.key}>{col.label}</th> // Hide column in the header
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((project) => (
                        <tr key={project.id}>
                            {columns.map(col => (
                                col.hidden ? null : (
                                    col.key !== 'actions' ? (
                                        <td key={col.key}>
                                            {col.inputType === 'text' && (
                                                <Form.Control
                                                    type="text"
                                                    value={project[col.key] || ''}
                                                    onChange={(e) => handleInputChange(project.id, col.key, e.target.value)}
                                                />
                                            )}
                                            {col.inputType === 'textarea' && (
                                                <Form.Control
                                                    as="textarea"
                                                    value={project[col.key] || ''}
                                                    onChange={(e) => handleInputChange(project.id, col.key, e.target.value)}
                                                />
                                            )}
                                            {col.inputType === 'select' && (
                                                <Form.Select
                                                    style={{ width: '150px' }} // Increase the width of the select box
                                                    value={project[col.key] || ''}
                                                    onChange={(e) => handleInputChange(project.id, col.key, e.target.value)}
                                                >
                                                    {col.options.map(option => (
                                                        <option key={option} value={option}>{option}</option>
                                                    ))}
                                                </Form.Select>
                                            )}
                                            {col.inputType === 'date' && (
                                                <Form.Control
                                                    type="date"
                                                    value={project[col.key] || ''}
                                                    onChange={(e) => handleInputChange(project.id, col.key, e.target.value)}
                                                />
                                            )}
                                            {col.inputType === 'file' && (
                                                <Form.Control
                                                    type="file"
                                                    onChange={(e) => handleInputChange(project.id, col.key, e.target.files[0])}
                                                />
                                            )}
                                        </td>
                                    ) : (
                                        <td key="actions">
                                            <Button
                                                variant="primary"
                                                onClick={() => handleSave(project)}
                                            >
                                                שמור
                                            </Button>
                                        </td>
                                    )
                                )
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="d-flex flex-column justify-content-between mb-3">
                <div className="col-md-3 ml-1">
                    <label>כמות פריטים להציג</label>
                    <select value={itemsPerPage} onChange={handleItemsPerPageChange} className="form-select">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                    </select>
                </div>
                <label className="mr-2 mt-2">מעבר בין עמודים</label>
                <div className="d-flex flex-row col-md-8 mt-2">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-light'}`}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProjectTable;
