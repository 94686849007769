import React, { useState, useEffect } from 'react';
import { getUserInfo } from '../../api/user';
import { getProjects, updateProjectInfo } from '../../api/project'; 
import { getTasks } from '../../api/tasks';
import { getContacts } from '../../api/contacts';
import { useTranslation } from 'react-i18next';
import TaskModal from '../../components/Tasks/TaskModal';
import DayTasksModal from '../../components/Tasks/DayTasksModal';

import CaseTable from '../../components/CaseTable'; // Import CaseTable component
import { toast } from 'react-toastify'; // Import toast for notifications

function Home() {
    const { t } = useTranslation();
    const [userInfo, setUserInfo] = useState(null);
    const [projects, setProjects] = useState([]); // State to hold all projects
    const [tasks, setTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [showTasksModal, setShowTasksModal] = useState(false);
    const [showDayTasksModal, setShowDayTasksModal] = useState(false);
    const [tasksForDay, setTasksForDay] = useState([]);
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const fetchedUserInfo = await getUserInfo();
                setUserInfo(fetchedUserInfo);
            } catch (error) {
                console.error("Error fetching user info:", error);
            }
        };
        const fetchProjects = async () => {
            try {
                const fetchedProjects = await getProjects(); // Fetch all projects
                setProjects(fetchedProjects);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };
        const fetchTasks = async () => {
            try {
                const fetchedTasks = await getTasks();
                setTasks(fetchedTasks.filter(task => task.status === 'todo'));
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };
        const fetchContacts = async () => {
            try {
                const fetchedContacts = await getContacts();
                setContacts(fetchedContacts);
            } catch (error) {
                console.error('Error fetching contacts:', error);
            }
        };

        fetchUserInfo();
        fetchProjects(); // Call fetchProjects to get all projects
        fetchTasks();
        fetchContacts();
    }, []);

    const handleProjectUpdate = async (updatedProject) => {
        try {
            const response = await updateProjectInfo(updatedProject);
            if (response) {
                const index = projects.findIndex(p => p.id === updatedProject.id);
                if (index !== -1) {
                    projects[index] = updatedProject;
                    setProjects([...projects]); // Trigger re-render
                }
                toast.success("Project updated successfully");
            } else {
                toast.error("Failed to update project");
            }
        } catch (error) {
            toast.error("Error updating project");
            console.error("Error updating project:", error);
        }
    };

    const handleNewProjectClick = () => {
        window.open('/newProject', '_blank');
    };
    const handleNewProjectMetroClick = () => {
        window.open('/AddNewProjectMetro', '_blank');
    };

    return (
        <div dir="rtl" className="container">
            <div className='pt-3'>
                <h3 className='mt-3 fw-500'>{t('greeting.welcome')}, {userInfo && userInfo.name.split(" ")[0]}!</h3>
            </div>
            <div className="row pt-3">
                <div className="bg-white rounded p-3 mt-3 shadow-sm">
                    <button className="btn btn-primary w-100" onClick={handleNewProjectClick}>
                        הפנייה חדשה
                    </button>
                    </div>
                <div className="bg-white rounded p-3 mt-3 shadow-sm">
                    <button className="btn btn-primary w-100" onClick={handleNewProjectMetroClick}>
                    הפנייה מטרופולין
                    </button>
                </div>
                <div className="col-md-12 p-3">
                    <CaseTable 
                        data={projects} 
                        onUpdate={handleProjectUpdate} 
                    />
                </div>
            </div>
        </div>
    );
}

export default Home;
