import React, { useState, useEffect } from 'react';
import { getUserInfo } from '../../api/user';
import { getProjectInfo, getProjects } from '../../api/project';
import { getMonthlySummaries, updateMonthlySummary } from '../../api/monthlySummary';
import { getSumOfDebt } from '../../api/collections';
import { getTasks, updateTask } from '../../api/tasks';
import Calendar from 'react-calendar';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import TaskModal from '../../components/Tasks/TaskModal';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'bootstrap/dist/css/bootstrap.min.css';

// Import toast components
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Utility function to format number with commas
const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function DashboardProject() {
    const { t } = useTranslation();
    const [userInfo, setUserInfo] = useState(null);
    const [monthlySummaries, setMonthlySummaries] = useState([]);
    const [totalIncomeFromSummaries, setTotalIncomeFromSummaries] = useState(0);
    const [totalExpensesFromSummaries, setTotalExpensesFromSummaries] = useState(0);
    const [monthlyIncomeData, setMonthlyIncomeData] = useState([]);
    const [monthlyExpensesData, setMonthlyExpensesData] = useState([]);
    const [debt, setDebt] = useState(0);
    const [projectsOpenedThisMonth, setProjectsOpenedThisMonth] = useState(0);
    const [value, onChange] = useState(new Date());

    const [tasks, setTasks] = useState([]);
    const [tasksOnSelectedDate, setTasksOnSelectedDate] = useState([]);
    const [lastFiveTodoTasks, setLastFiveTodoTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [showTasksModal, setShowTasksModal] = useState(false);
    const [showDateTasksModal, setShowDateTasksModal] = useState(false);

    useEffect(() => {
        fetchUserInfo();
        fetchMonthlySummaries();
        fetchSumOfDebt();
        fetchProjectsOpenedThisMonth();
        fetchTasks();
    }, []);

    const fetchUserInfo = async () => {
        try {
            const fetchedUserInfo = await getUserInfo();
            setUserInfo(fetchedUserInfo);
        } catch (error) {
            console.error("Error fetching user info:", error);
        }
    };

    const fetchMonthlySummaries = async () => {
        try {
            const fetchedSummaries = await getMonthlySummaries();
            setMonthlySummaries(fetchedSummaries);
            calculateSummaryTotals(fetchedSummaries);
            prepareChartData(fetchedSummaries);
        } catch (error) {
            console.error('Error fetching monthly summaries:', error);
        }
    };

    const fetchSumOfDebt = async () => {
        try {
            const sum = await getSumOfDebt();
            setDebt(sum);
        } catch (error) {
            console.error('Error fetching sum of debt:', error);
        }
    };

    const fetchProjectsOpenedThisMonth = async () => {
        try {
            const fetchedProjects = await getProjects();
            const currentMonth = new Date().getMonth();
            const currentYear = new Date().getFullYear();
            const openedThisMonth = fetchedProjects.filter(project => {
                if (project.created_at) {
                    const projectDate = new Date(project.created_at);
                    return projectDate.getMonth() === currentMonth && projectDate.getFullYear() === currentYear;
                }
                return false;
            }).length;
            setProjectsOpenedThisMonth(openedThisMonth);
        } catch (error) {
            console.error('Error fetching projects opened this month:', error);
        }
    };

    const fetchTasks = async () => {
        try {
            const fetchedTasks = await getTasks();
            setTasks(fetchedTasks);
            const todoTasks = fetchedTasks.filter(task => task.status === 'todo');
            const lastFive = todoTasks.slice(-5);
            setLastFiveTodoTasks(lastFive);
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };

    const handleDateClick = (date) => {
        const selectedTasks = tasks.filter(task => {
            return new Date(task.due_date).toDateString() === date.toDateString();
        });
        setTasksOnSelectedDate(selectedTasks);
        setShowDateTasksModal(true);
    };

    const calculateSummaryTotals = (summaries) => {
        const currentYear = new Date().getFullYear();
        let totalIncome = 0;
        let totalExpenses = 0;

        summaries.forEach(summary => {
            if (summary.year === currentYear) {
                totalIncome += Number(summary.total_income) || 0;
                totalExpenses += Number(summary.total_expenses) || 0;
            }
        });

        setTotalIncomeFromSummaries(totalIncome);
        setTotalExpensesFromSummaries(totalExpenses);
    };

    const prepareChartData = (summaries) => {
        const currentYear = new Date().getFullYear();
        const incomeByMonth = Array(12).fill(0);
        const expensesByMonth = Array(12).fill(0);

        summaries.forEach(summary => {
            if (summary.year === currentYear && summary.month >= 1 && summary.month <= 12) {
                const monthIndex = summary.month - 1;
                incomeByMonth[monthIndex] += Number(summary.total_income) || 0;
                expensesByMonth[monthIndex] += Number(summary.total_expenses) || 0;
            }
        });

        setMonthlyIncomeData(incomeByMonth);
        setMonthlyExpensesData(expensesByMonth);
    };

    const chartData = {
        labels: [
            'ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני',
            'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'
        ],
        datasets: [
            {
                label: 'סה"כ הכנסות',
                data: monthlyIncomeData,
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
                tension: 0.1,
            },
            {
                label: 'סה"כ הוצאות',
                data: monthlyExpensesData,
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                fill: true,
                tension: 0.1,
            },
        ],
    };

    const options = {
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
        },
    };

    const handleTaskInputChange = (taskId, field, value) => {
        setTasks(prevTasks => {
            const updatedTasks = prevTasks.map(task => 
                task.id === taskId ? { ...task, [field]: value } : task
            );
          
            return updatedTasks;
        });

        setLastFiveTodoTasks(prevTasks => {
            const updatedTasks = prevTasks.map(task => 
                task.id === taskId ? { ...task, [field]: value } : task
            );
            console.log('Updated lastFiveTodoTasks:', updatedTasks);
            return updatedTasks;
        });
    };

    const handleTaskUpdate = async (taskId) => {
        try {
            const taskToUpdate = tasks.find(task => task.id === taskId);
            if (!taskToUpdate) return;

            await updateTask(taskToUpdate);
            
            // Show success toast
            toast.success('Task updated successfully!');
            
            // Refresh tasks after update
            fetchTasks();
        } catch (error) {
            console.error('Error updating task:', error);
            // Show error toast
            toast.error('Failed to update task. Please try again.');
        }
    };

    return (
        <>
            <div className="container-fluid" dir={i18n.language === 'he' ? 'rtl' : 'ltr'}>
                <div className='pt-3'>
                    <h3 className='mt-3 fw-500'>{t('greeting.welcome')}, {userInfo && userInfo.name.split(" ")[0]}!</h3>
                </div>

                <div className="row pt-3">
                    <div className="col-md-12">
                        <div className="bg-white rounded p-3 mt-3 shadow-sm">
                            <h6 className='bold mb-3'>היומן שלי</h6>
                            <Calendar 
                                onChange={onChange} 
                                value={value} 
                                tileContent={({ date }) => {
                                    const hasTask = tasks.some(task => 
                                        new Date(task.due_date).toDateString() === date.toDateString()
                                    );
                                    return hasTask ? <div className="task-indicator"></div> : null;
                                }}
                                onClickDay={handleDateClick}
                                className="w-100 border-0 px-4 my-4" 
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        {/* Buttons for different forms */}
                  
                        <button 
                            className="btn btn-primary mt-3" 
                            onClick={() => window.open('/Releaseform', '_blank')}
                        >
                            כתב סילוק
                            </button>
                        <button 
                            className="btn btn-primary mt-3" 
                            onClick={() => window.open('/replacecar', '_blank')}
                        >
                            טופס לרכב חליפי
                        </button>
                    </div>

                    <div className="col-md-12">
                        <div className="bg-white rounded p-3 mt-3 shadow-sm">
                            <h6 className='bold mb-3'>משימות מנכ"ל</h6>
                            {lastFiveTodoTasks.length > 0 ? (
                                <div className="list-group">
                                    {lastFiveTodoTasks.map((task, index) => (
                                        <div key={task.id} className={`list-group-item d-flex align-items-center ${index % 2 === 0 ? 'bg-light' : ''}`}>
                                            <input
                                                type="text"
                                                className="form-control mx-1 high-contrast-input"
                                                style={{ backgroundColor: 'white' }}
                                                value={task.subject || ''}
                                                onChange={(e) => handleTaskInputChange(task.id, 'subject', e.target.value)}
                                                placeholder="נושא"
                                            />
                                            <textarea
                                                className="form-control mx-1 high-contrast-input"
                                                style={{ backgroundColor: 'white' }}
                                                value={task.description || ''}
                                                onChange={(e) => handleTaskInputChange(task.id, 'description', e.target.value)}
                                                placeholder="תיאור"
                                            />
                                            <select
                                                className="form-control mx-1 high-contrast-input"
                                                style={{ backgroundColor: 'white' }}
                                                value={task.status || 'todo'}
                                                onChange={(e) => handleTaskInputChange(task.id, 'status', e.target.value)}
                                            >
                                                <option value="todo">צריך לעשות</option>
                                                <option value="in_progress">בתהליך</option>
                                                <option value="done">בוצע</option>
                                            </select>
                                            <button
                                                onClick={() => handleTaskUpdate(task.id)}
                                                className="btn btn-primary mx-1"
                                            >
                                                עדכן
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p className='py-5 text-center'>אין משימות מאוחרות 😊</p>
                            )}
                        </div>
                    </div>
                </div>

                {/* Modal for showing tasks on the selected date */}
                {showDateTasksModal && (
                    <div className="modal fade show d-block" tabIndex="-1" role="dialog">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">משימות לתאריך {value.toDateString()}</h5>
                                    <button type="button" className="close" onClick={() => setShowDateTasksModal(false)}>
                                        <span>&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {tasksOnSelectedDate.length > 0 ? (
                                        <ul className="zebra-list">
                                            {tasksOnSelectedDate.map((task, index) => (
                                                <li key={task.id} className={index % 2 === 0 ? 'zebra-even' : 'zebra-odd'}>
                                                    <h6>{task.subject}</h6>
                                                    <p><strong>תיאור:</strong> {task.description}</p>
                                                    <p><strong>תאריך יעד:</strong> {new Date(task.due_date).toLocaleDateString('he-IL')}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>אין משימות לתאריך זה.</p>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowDateTasksModal(false)}>
                                        סגור
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/* Toast Container for displaying notifications */}
            <ToastContainer />
        </>
    );
}

export default DashboardProject;
