import React, { useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/img/logo-atia.jpeg';

function ReleaseForm() {
    const [formData, setFormData] = useState({
        caseNumber: '',
        idNumber: '',
        fullName: '',
        carNumber: '',
        licensePlate: '',
        carModel: '',
        incidentLocation: '',
        incidentDate: '',
        incidentTime: '',
        signature: ''
    });

    const sigCanvas = useRef({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleClearSignature = () => {
        sigCanvas.current.clear();
        setFormData(prevState => ({ ...prevState, signature: '' }));
    };

    const handleSaveSignature = () => {
        const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        setFormData(prevState => ({ ...prevState, signature }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Submitted Data:", formData);

        // Show success toast
        toast.success("הטופס נשלח בהצלחה!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        // Submit logic goes here (e.g., send data to an API)
    };

    return (
        <div className="bg-white rounded p-5 shadow-sm d-flex flex-column align-items-center justify-content-center" dir="rtl" style={{ minHeight: '100vh' }}>
            <ToastContainer />
            
            <div className="text-center mb-3">
                <h2>כתב קבלה/ויתור/שחרור/סגירת תביעות</h2>
                <img src={logo} alt="Logo" style={{ width: '150px' }} />
            </div>

            <form onSubmit={handleSubmit} style={{ maxWidth: '600px', width: '100%' }}>
                <div className="mb-3">
                    <label>מספר תיק</label>
                    <input
                        type="text"
                        className="form-control"
                        name="caseNumber"
                        placeholder="מספר תיק"
                        value={formData.caseNumber}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>שם מלא</label>
                    <input
                        type="text"
                        className="form-control"
                        name="fullName"
                        placeholder="שם מלא"
                        value={formData.fullName}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>ת.ז. מספר</label>
                    <input
                        type="text"
                        className="form-control"
                        name="idNumber"
                        placeholder="מספר תעודת זהות"
                        value={formData.idNumber}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>מספר רכב</label>
                    <input
                        type="text"
                        className="form-control"
                        name="carNumber"
                        placeholder="מספר רכב"
                        value={formData.carNumber}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>לוחית רישוי</label>
                    <input
                        type="text"
                        className="form-control"
                        name="licensePlate"
                        placeholder="לוחית רישוי"
                        value={formData.licensePlate}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>סוג הרכב</label>
                    <input
                        type="text"
                        className="form-control"
                        name="carModel"
                        placeholder="סוג הרכב"
                        value={formData.carModel}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>מיקום האירוע</label>
                    <input
                        type="text"
                        className="form-control"
                        name="incidentLocation"
                        placeholder="מיקום האירוע"
                        value={formData.incidentLocation}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>תאריך האירוע</label>
                    <input
                        type="date"
                        className="form-control"
                        name="incidentDate"
                        value={formData.incidentDate}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>שעת האירוע</label>
                    <input
                        type="time"
                        className="form-control"
                        name="incidentTime"
                        value={formData.incidentTime}
                        onChange={handleChange}
                    />
                </div>

                <h4>הצהרות נוספות</h4>
                <p>1. מאשר לאשר את קבלת סכום כסף בסך _____________ (סכום מפורש) מאת _______________ מחברת מטורופולין בע"מ (להלן: החברה הציבורית). סכום זה מהווה תשלום סופי בעבור סגירת כל ענייני תיקון זה סופית, ומאשר כי מצב זה חדל קדם למצג שלם יותר.</p>
                <p>2. ו/או למחוק את המיותר.</p>
                <p>3. אני מצהיר כי בתמורה לכך החזרתי את הרכב במצב המוסכם לתוך המפורטון כבדיקה, סודר תיקון רכב ואין לי דרישות נוספות.</p>
                <p>4. מצהיר שלא הייתה לי התנגשות בין רכביי, ואין לי תביעות כלשהן כלפי חברה זו.</p>
                <p>5. כל נזק, פגיעה, תביעה כלשהי כבר נסגרו בהתאם לחוק.</p>

                <h4>חתימה דיגיטלית</h4>
                <SignatureCanvas
                    ref={sigCanvas}
                    penColor="black"
                    canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                    onEnd={handleSaveSignature}
                />
                <button type="button" className="btn btn-secondary mt-2" onClick={handleClearSignature}>
                    נקה חתימה
                </button>

                {formData.signature && (
                    <div className="mt-3">
                        <img
                            src={formData.signature}
                            alt="Digital Signature"
                            style={{ marginTop: '10px', maxWidth: '200px' }}
                        />
                    </div>
                )}

                <button type="submit" className="btn btn-primary mt-3">שלח</button>
            </form>
        </div>
    );
}

export default ReleaseForm;
