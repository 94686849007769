import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function DailySummaryModal({ show, onHide, currentSummary, onSave }) {
    const [isUpdate, setIsUpdate] = useState(false);  // Track if updating or creating
    const [summaryData, setSummaryData] = useState({
        date: '',  // Store date instead of year and month
        total_vehicles: '',
        total_income: '',
        total_expenses: '',
        gross_profit: ''
    });

    // Set today's date as default
    useEffect(() => {
        const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
        if (!currentSummary) {
            setSummaryData((prevData) => ({
                ...prevData,
                date: today  // Set default date to today
            }));
        }
    }, [currentSummary]);

    useEffect(() => {
        if (currentSummary) {
            setSummaryData(currentSummary);
            setIsUpdate(true);  // Set to update mode if currentSummary exists
        } else {
            setSummaryData({
                date: new Date().toISOString().split('T')[0],  // Set default to today
                total_vehicles: '',
                total_income: '',
                total_expenses: '',
                gross_profit: ''
            });
            setIsUpdate(false);  // Set to create mode if no currentSummary
        }
    }, [currentSummary]);

    // Automatically calculate gross profit whenever total_income or total_expenses changes
    useEffect(() => {
        const income = parseFloat(summaryData.total_income || 0);
        const expenses = parseFloat(summaryData.total_expenses || 0);
        setSummaryData((prevData) => ({
            ...prevData,
            gross_profit: (income - expenses).toFixed(2),  // Automatically update gross_profit
        }));
    }, [summaryData.total_income, summaryData.total_expenses]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSummaryData({
            ...summaryData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(summaryData, isUpdate);  // Pass the isUpdate flag to determine if it's an update or a create action
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{isUpdate ? 'ערוך תקציר יומי' : 'הוסף תקציר יומי'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>תאריך</Form.Label>
                        <Form.Control
                            type="date"
                            name="date"
                            value={summaryData.date}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
 
                    <Form.Group className="mb-3">
                        <Form.Label>מספר תיק</Form.Label>
                        <Form.Control
                            type="number"
                            name="case_number"
                            value={summaryData.total_vehicles}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>סה"כ הכנסות</Form.Label>
                        <Form.Control
                            type="number"
                            name="total_income"
                            value={summaryData.total_income}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>סה"כ הוצאות</Form.Label>
                        <Form.Control
                            type="number"
                            name="total_expenses"
                            value={summaryData.total_expenses}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    
                    {/* Gross Profit is automatically calculated and read-only */}
                    <Form.Group className="mb-3">
                        <Form.Label>רווח גולמי</Form.Label>
                        <Form.Control
                            type="number"
                            name="gross_profit"
                            value={summaryData.gross_profit}
                            readOnly  // Disable manual input for gross_profit
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        {isUpdate ? 'שמור שינויים' : 'צור תקציר חדש'}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default DailySummaryModal;
