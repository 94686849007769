import React, { useState } from "react";
import {
  faEllipsis,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveTask, updateTask } from "../../api/tasks";
import TaskCard from "./TaskCard";
import CreateTaskCard from "./CreateTaskCard";
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function Board({
  tasks,
  setTasks,
  setSelectedTask,
  selectedTask,
  showTasksModal,
  setShowTasksModal,
  showAddTaskCard,
  setShowAddTaskCard,
}) {
  const [subject, setSubject] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState(""); // Define email state
  const [phone, setPhone] = useState(""); // Define phone state

  const { t } = useTranslation();

  const handleShowTaskModal = (task) => {
    setSelectedTask(task);
    setShowTasksModal(true);
  };

  const handleShowAddTaskCard = () => {
    setShowAddTaskCard(true);
  };

  const handleHideAddTaskCard = () => {
    setShowAddTaskCard(false);
  };

  const handleSaveTask = async () => {
    try {
      // Pass individual fields to the saveTask function
      const newTask = await saveTask(subject, description, dueDate, "TaskableType", "TaskableID");
      setTasks([newTask, ...tasks]);
      setShowAddTaskCard(false);
      setSubject("");
      setDescription("");
      setEmail("");
      setPhone("");
    } catch (error) {
      console.error("Failed to save task:", error);
    }
  };

  // Define the handleArchive function
  const handleArchive = (taskId) => {
    setTasks(tasks.map(task => 
      task.id === taskId ? { ...task, status: 'archived' } : task
    ));
    console.log('Archiving task with ID:', taskId);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    const reorderedTasks = Array.from(tasks);
    const [movedTask] = reorderedTasks.splice(source.index, 1);

    movedTask.status = destination.droppableId;

    reorderedTasks.splice(destination.index, 0, movedTask);

    setTasks(reorderedTasks); 

    try {
      await updateTask(movedTask);
    } catch (error) {
      console.error("Failed to update task:", error);
    }

    // Refresh the tasks after drag-and-drop by setting the state again
    setTasks([...reorderedTasks]);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="row">
        <div className="col-12">
          <button
            className="btn btn-primary py-2 rounded w-30 small"
            onClick={handleShowAddTaskCard}
            style={{ textDecoration: 'none',  fontSize: "12px" }}
          >
            <FontAwesomeIcon icon={faPlus} className="pe-2  small" />
            הוסף משימה
          </button>

          <CreateTaskCard
            showAddTaskCard={showAddTaskCard}
            tasks={tasks}
            subject={subject}
            setSubject={setSubject}
            dueDate={dueDate}
            setDueDate={setDueDate}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
            description={description}
            setDescription={setDescription}
            handleHideAddTaskCard={handleHideAddTaskCard}
            handleSaveTask={handleSaveTask}
          />
        </div>

        {/* Define Droppable columns with borders between them */}
        <Droppable droppableId="todo">
          {(provided) => (
            <div className="col-12 col-md-3 p-3" {...provided.droppableProps} ref={provided.innerRef} style={{ borderRight: "1px solid #ddd" }}> {/* Add right border to create separation */}
              <div className="d-flex justify-content-between">
                <p className="mb-3 fw-500">
                  תחילת עבודה
                  <span className="text-muted fw-400 medium px-2">{tasks.filter((task) => task.status === "todo").length}</span>
                </p>
                <div>
                  <button className="px-2 btn" onClick={handleShowAddTaskCard}>
                    <FontAwesomeIcon icon={faPlus} className="text-muted" />
                  </button>
                  <button className="px-2 btn">
                    <FontAwesomeIcon icon={faEllipsis} className="text-muted" />
                  </button>
                </div>
              </div>

              <div
                className={`bg-${
                  tasks.filter((task) => task.status === "todo").length === 0 &&
                  tasks.length > 0
                    ? "gray py-5"
                    : ""
                } w-100 rounded`}
              >
                {tasks
                  .filter((task) => task.status === "todo")
                  .map((task, index) => (
                    <Draggable key={task.id} draggableId={String(task.id)} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TaskCard
                            key={task.id}
                            task={task}
                            handleShowTaskModal={handleShowTaskModal}
                            handleArchive={handleArchive}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>

        <Droppable droppableId="in_progress">
          {(provided) => (
            <div className="col-12 col-md-3 p-3" {...provided.droppableProps} ref={provided.innerRef} style={{ borderRight: "1px solid #ddd" }}> {/* Add right border */}
              <div className="d-flex justify-content-between">
                <p className="mb-3 fw-500">
                  בעבודה
                  <span className="text-muted fw-400 medium px-2">{tasks.filter((task) => task.status === "in_progress").length}</span>
                </p>
                <div>
                  <button className="px-2 btn" onClick={handleShowAddTaskCard}>
                    <FontAwesomeIcon icon={faPlus} className="text-muted" />
                  </button>
                  <button className="px-2 btn">
                    <FontAwesomeIcon icon={faEllipsis} className="text-muted" />
                  </button>
                </div>
              </div>
              <div
                className={`bg-${
                  tasks.filter((task) => task.status === "in_progress").length === 0
                    ? "gray py-5"
                    : ""
                } w-100 rounded`}
              >
                {tasks
                  .filter((task) => task.status === "in_progress")
                  .map((task, index) => (
                    <Draggable key={task.id} draggableId={String(task.id)} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TaskCard
                            key={task.id}
                            task={task}
                            handleShowTaskModal={handleShowTaskModal}
                            handleArchive={handleArchive}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>

        {/* Repeat this border separation for other columns */}
        <Droppable droppableId="on_hold">
          {(provided) => (
            <div className="col-12 col-md-3 p-3" {...provided.droppableProps} ref={provided.innerRef} style={{ borderRight: "1px solid #ddd" }}> {/* Add right border */}
              <div className="d-flex justify-content-between">
                <p className="mb-3 fw-500">
                  הקפאה
                  <span className="text-muted fw-400 medium px-2">{tasks.filter((task) => task.status === "on_hold").length}</span>
                </p>
                <div>
                  <button className="px-2 btn" onClick={handleShowAddTaskCard}>
                    <FontAwesomeIcon icon={faPlus} className="text-muted" />
                  </button>
                  <button className="px-2 btn">
                    <FontAwesomeIcon icon={faEllipsis} className="text-muted" />
                  </button>
                </div>
              </div>
              <div
                className={`bg-${
                  tasks.filter((task) => task.status === "on_hold").length === 0
                    ? "gray py-5"
                    : ""
                } w-100 rounded`}
              >
                {tasks
                  .filter((task) => task.status === "on_hold")
                  .map((task, index) => (
                    <Draggable key={task.id} draggableId={String(task.id)} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TaskCard
                            key={task.id}
                            task={task}
                            handleShowTaskModal={handleShowTaskModal}
                            handleArchive={handleArchive}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>

        <Droppable droppableId="done">
          {(provided) => (
            <div className="col-12 col-md-3 p-3" {...provided.droppableProps} ref={provided.innerRef}>
              <div className="d-flex justify-content-between">
                <p className="mb-3 fw-500">
                  הסתיימו
                  <span className="text-muted fw-400 medium px-2">{tasks.filter((task) => task.status === "done").length}</span>
                </p>
                <div>
                  <button className="px-2 btn" onClick={handleShowAddTaskCard}>
                    <FontAwesomeIcon icon={faPlus} className="text-muted" />
                  </button>
                  <button className="px-2 btn">
                    <FontAwesomeIcon icon={faEllipsis} className="text-muted" />
                  </button>
                </div>
              </div>
              <div
                className={`bg-${
                  tasks.filter((task) => task.status === "done").length === 0
                    ? "gray py-5"
                    : ""
                } w-100 rounded`}
              >
                {tasks
                  .filter((task) => task.status === "done")
                  .map((task, index) => (
                    <Draggable key={task.id} draggableId={String(task.id)} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TaskCard
                            key={task.id}
                            task={task}
                            handleShowTaskModal={handleShowTaskModal}
                            handleArchive={handleArchive}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
}

export default Board;
