import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faEllipsisV } from "@fortawesome/free-solid-svg-icons"; // Added icon for dropdown
import { TaskPropType } from "../../lib/propTypes";
import { useTranslation } from 'react-i18next';

const TaskCard = ({ task, handleShowTaskModal, handleArchive }) => {
  const { t } = useTranslation();

  // Function to generate Google Calendar URL
  const generateGoogleCalendarUrl = (task) => {
    const baseUrl = "https://calendar.google.com/calendar/render";
    const dueDate = task.due_date ? new Date(task.due_date) : null;
    const subject = task.subject ? encodeURIComponent(task.subject) : "";

    const details = encodeURIComponent(`Description: ${task.description}\nPhone: ${task.phone}\nEmail: ${task.email}`);
    
    if (dueDate) {
      const startDate = new Date(dueDate);
      startDate.setHours(9, 0, 0); // Start at 9:00 AM
      const endDate = new Date(dueDate);
      endDate.setHours(10, 0, 0); // End at 10:00 AM

      const startDateTime = startDate.toISOString().replace(/-|:|\.\d\d\d/g, "");
      const endDateTime = endDate.toISOString().replace(/-|:|\.\d\d\d/g, "");

      return `${baseUrl}?action=TEMPLATE&text=${subject}&dates=${startDateTime}/${endDateTime}&details=${details}&location=&sf=true&output=xml`;
    }
    
    return `${baseUrl}?action=TEMPLATE&text=${subject}&details=${details}&sf=true&output=xml`;
  };

  return (
    <div className="task-card mb-2" style={{ padding: "8px", fontSize: "12px" }}>
      <div className="d-flex align-items-center justify-content-between">
        <button
          className="task-card-button btn btn-link"
          onClick={() => handleShowTaskModal(task)}
          style={{ textDecoration: 'none', color: 'inherit', padding: 0 }}
        >

          <span className="small">{task.subject}</span>
        </button>

        <div className="dropdown">
          <button
            className="btn btn-link dropdown-toggle"
            type="button"
            id="taskDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ textDecoration: 'none', color: 'inherit', fontSize: "12px" }}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </button>
          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="taskDropdown">
            <li>
              <a
                className="dropdown-item"
                href={generateGoogleCalendarUrl(task)}
                target="_blank"
                rel="noopener noreferrer"
              >
                הוספה לגוגל
              </a>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={(e) => { e.stopPropagation(); handleArchive(task.id); }}
              >
                לארכיון
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

TaskCard.propTypes = {
  task: TaskPropType.isRequired,
  handleShowTaskModal: PropTypes.func.isRequired,
  handleArchive: PropTypes.func.isRequired,
};

export default TaskCard;
