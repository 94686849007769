import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { getMonthlySummaries, updateMonthlySummary, saveMonthlySummary } from '../../api/monthlySummary';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MonthlySummaryModal from './monthlySummaryModal';
import DailySummaryModal from './DailySummaryModal';

function MonthlySummaryTable() {
    const [summaries, setSummaries] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [showModal, setShowModal] = useState(false);
    const [currentSummary, setCurrentSummary] = useState(null);
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [uniqueYears, setUniqueYears] = useState([]);

    useEffect(() => {
        fetchMonthlySummaries();
    }, []);

    const fetchMonthlySummaries = async () => {
        try {
            const data = await getMonthlySummaries();
            setSummaries(data);
            setFilteredData(data);
            
            const years = [...new Set(data.map(summary => summary.year))];
            setUniqueYears(years);
        } catch (error) {
            toast.error('שגיאה בטעינת תקצירים');
            console.error('Error fetching monthly summaries:', error);
        }
    };

    useEffect(() => {
        const filtered = summaries.filter((summary) => {
            const matchesSearchTerm = Object.values(summary).some((value) =>
                value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            );
            const matchesYear = selectedYear ? summary.year === parseInt(selectedYear) : true;
            const matchesMonth = selectedMonth ? summary.month === parseInt(selectedMonth) : true;
            return matchesSearchTerm && matchesYear && matchesMonth;
        });
        setFilteredData(filtered);
    }, [searchTerm, selectedYear, selectedMonth, summaries]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleEditClick = (summary) => {
        setCurrentSummary(summary);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setCurrentSummary(null);
    };

    const handleAddNewClick = () => {
        setCurrentSummary(null);
        setShowModal(true);
    };

    const handleInvoiceChange = async (summary) => {
        const updatedSummary = {
            ...summary,
            is_invoice: !summary.is_invoice,
            project_id: summary.id,
        };

        try {
            const updatedData = await updateMonthlySummary(updatedSummary);
            toast.success('סטטוס החשבונית עודכן בהצלחה');

            setSummaries((prevSummaries) =>
                prevSummaries.map((item) =>
                    item.id === updatedData.id ? updatedData : item
                )
            );
            setFilteredData((prevFilteredData) =>
                prevFilteredData.map((item) =>
                    item.id === updatedData.id ? updatedData : item
                )
            );
        } catch (error) {
            toast.error('שגיאה בעדכון סטטוס החשבונית');
            console.error('Error updating invoice status:', error);
        }
    };

    const handleSave = async (summaryData, isUpdate) => {
        // Ensure day is properly handled
        const dataToSend = {
            ...summaryData,
            day: summaryData.day ? summaryData.day : null,  // Set a default if necessary
        };
    
        try {
            if (isUpdate) {
                await updateMonthlySummary(dataToSend);
                toast.success('התקציר החודשי עודכן בהצלחה');
            } else {
                await saveMonthlySummary(dataToSend);
                toast.success('התקציר החודשי נוצר בהצלחה');
            }
            fetchMonthlySummaries();
            handleModalClose();
        } catch (error) {
            toast.error('שגיאה בעדכון התקציר החודשי');
            console.error('Error saving monthly summary:', error);
        }
    };
    

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    return (
        <div className='bg-white rounded p-3 shadow-sm mt-5'>
            <div className="row mb-3">
                <div className="col-md-3">
                    <input 
                        type="text" 
                        placeholder="חיפוש" 
                        value={searchTerm} 
                        onChange={handleSearch} 
                        className="form-control"
                    />
                </div>
                <div className="col-md-3">
                    <select value={selectedYear} onChange={handleYearChange} className="form-select">
                        <option value="">בחר שנה</option>
                        {uniqueYears.map((year) => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3">
                    <select value={selectedMonth} onChange={handleMonthChange} className="form-select">
                        <option value="">בחר חודש</option>
                        <option value="1">ינואר</option>
                        <option value="2">פברואר</option>
                        <option value="3">מרץ</option>
                        <option value="4">אפריל</option>
                        <option value="5">מאי</option>
                        <option value="6">יוני</option>
                        <option value="7">יולי</option>
                        <option value="8">אוגוסט</option>
                        <option value="9">ספטמבר</option>
                        <option value="10">אוקטובר</option>
                        <option value="11">נובמבר</option>
                        <option value="12">דצמבר</option>
                    </select>
                </div>
            </div>

            <Button variant="primary" onClick={handleAddNewClick} className="mb-3">
               הוסף סיכום יומי
            </Button>

            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>מספר תיק</th>
                        <th>שנה</th>
                        <th>חודש</th>
                        <th>שבוע</th>
                        <th>סה"כ הכנסות</th>
                        <th>סה"כ הוצאות</th>
                        <th>רווח גולמי</th>
                        <th>יש חשבונית</th>
                        <th>פעולות</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((summary) => (
                        <tr key={summary.id}>
                            <td>{summary.project_name}</td>
                            <td>{summary.year}</td>
                            <td>{summary.month}</td>
                            <td>{summary.week}</td>
                            <td>{summary.total_income}</td>
                            <td>{summary.total_expenses}</td>
                            <td>{summary.gross_profit}</td>
                            <td>
                                <input 
                                    type="checkbox" 
                                    checked={summary.is_invoice}
                                    onChange={() => handleInvoiceChange(summary)} 
                                />
                            </td>
                            <td>
                                <button className="btn btn-primary me-2" onClick={() => handleEditClick(summary)}>
                                    עריכה
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="d-flex flex-column justify-content-between mb-3">
                <div className="col-md-3 ml-1">
                    <label>פריטים בעמוד</label>
                    <select value={itemsPerPage} onChange={handleItemsPerPageChange} className="form-select">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                    </select>
                </div>
                <label className="mr-2 mt-2">מעבר בין עמודים</label>
                <div className="d-flex flex-row col-md-8 mt-2">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-light'}`}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>

            {showModal && (
                <DailySummaryModal
                    show={showModal}
                    onHide={handleModalClose}
                    currentSummary={currentSummary}
                    onSave={handleSave}
                />
            )}
        </div>
    );
}

export default MonthlySummaryTable;