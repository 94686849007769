import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { saveProject } from '../../api/project.js';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import logo from '../../assets/img/logo-atia.jpeg';

function AddNewProject() {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const [formData, setFormData] = useState({
    referring_party: '',
    name: '',
    company_name: '',
    case_number: '',
    bus_license_number: '',
    accident_date: '',
    third_party_license_number: '',
    third_party_name_and_phone: '',
    initial_call_with_third_party: '',
    event_description: '',
    region: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const handleFileChange = (e) => {
    setFormData({ ...formData, damage_photo_with_case_number: e.target.files[0] });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleReferringPartyChange = (e) => {
    const value = e.target.value;
    if (value === 'other') {
      setIsOtherSelected(true);
      setFormData({ ...formData, referring_party: '' });
    } else {
      setIsOtherSelected(false);
      setFormData({ ...formData, referring_party: value });
    }
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.name) errors.name = 'שדה חובה';
  
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const projectData = new FormData();
    Object.keys(formData).forEach(key => {
      projectData.append(key, formData[key]);
    });

    try {
      await saveProject(projectData);
      toast.success(t('addNewProject.success_message'));
      navigate('/dashboard'); // Redirect to the dashboard after successful submission
    } catch (error) {
      toast.error(t('addNewProject.error_message'));
    }
  };

  return (
    <div className="bg-white rounded p-3 shadow-sm" dir="rtl">
      <div className="row">
        <div className="col-3"></div>
        <div className="col-md-6 align-items-center">
          <h6 className="bold mb-3">הפנייה חדשה למוסך יהודה עטיה</h6>
          <div className="col-md-12 d-flex align-items-center justify-content-center">
          <img src={logo} alt="Logo" className="img-fluid" width={"300px"} />
        </div>
          <form onSubmit={handleSubmit}>
          <div className="mb-3">
              <label htmlFor="referring_party" className="form-label">גורם מפנה</label>
              <select 
                className="form-control" 
                id="referring_party" 
                name="referring_party" 
                value={formData.referring_party} 
                onChange={handleReferringPartyChange}
              >
                <option value="">בחר</option>
                <option value="נופר ביטון">נופר ביטון</option>
                <option value="פאולינה">פאולינה</option>
                <option value="ליגל">ליגל</option>
                <option value="לורנה">לורנה</option>
                <option value="סופיה">סופיה</option>
                <option value="נופר ביטום">נופר ביטום</option>
                <option value="אסף">אסף</option>
                <option value="אפי">אפי</option>
                <option value="אודי">אודי</option>
                <option value="other">אחר</option>
              </select>
              {isOtherSelected && (
                <input 
                  type="text" 
                  className="form-control mt-2" 
                  placeholder="הזן גורם מפנה" 
                  name="referring_party" 
                  value={formData.referring_party} 
                  onChange={handleChange} 
                />
              )}
              {formErrors.referring_party && <div className="text-danger">{formErrors.referring_party}</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">מספר תיק</label>
              <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} />
              {formErrors.name && <div className="text-danger">{formErrors.name}</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="accident_date" className="form-label">תאריך תאונה</label>
              <input type="date" className="form-control" id="accident_date" name="accident_date" value={formData.accident_date} onChange={handleChange} />
              {formErrors.accident_date && <div className="text-danger">{formErrors.accident_date}</div>}
            </div>
          

         
            <div className="mb-3">
              <label htmlFor="bus_license_number" className="form-label">מספר רישוי אוטובוס</label>
              <input type="text" className="form-control" id="bus_license_number" name="bus_license_number" value={formData.bus_license_number} onChange={handleChange} />
              {formErrors.bus_license_number && <div className="text-danger">{formErrors.bus_license_number}</div>}
            </div>

            <div className="mb-3">
              <label htmlFor="third_party_license_number" className="form-label">מספר רישוי צד ג</label>
              <input type="text" className="form-control" id="third_party_license_number" name="third_party_license_number" value={formData.third_party_license_number} onChange={handleChange} />
              {formErrors.third_party_license_number && <div className="text-danger">{formErrors.third_party_license_number}</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="third_party_name_and_phone" className="form-label">שם ומספר טלפון צד ג</label>
              <input type="text" className="form-control" id="third_party_name_and_phone" name="third_party_name_and_phone" value={formData.third_party_name_and_phone} onChange={handleChange} />
              {formErrors.third_party_name_and_phone && <div className="text-danger">{formErrors.third_party_name_and_phone}</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="initial_call_with_third_party" className="form-label">האם בוצעה שיחה ראשונית עם צד ג</label>
              <select className="form-control" id="initial_call_with_third_party" name="initial_call_with_third_party" value={formData.initial_call_with_third_party} onChange={handleChange}>
                <option value="">בחר</option>
                <option value="כן">כן מעוניין</option>
                <option value="מתלבט">כן מתלבט</option>
                <option value="לא">לא </option>
              </select>
              {formErrors.initial_call_with_third_party && <div className="text-danger">{formErrors.initial_call_with_third_party}</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="event_description" className="form-label">תיאור אירוע</label>
              <textarea className="form-control" id="event_description" name="event_description" rows="3" value={formData.event_description} onChange={handleChange}></textarea>
              {formErrors.event_description && <div className="text-danger">{formErrors.event_description}</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="region" className="form-label">אזור בארץ</label>
              <input type="text" className="form-control" id="region" name="region" value={formData.region} onChange={handleChange} />
              {formErrors.region && <div className="text-danger">{formErrors.region}</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="damage_photo_with_case_number" className="form-label">תמונת נזק לשלוח לואטסאפ בצירוף מס תיק !!! (חשוב מאוד)</label>
              <input type="file" className="form-control" id="damage_photo_with_case_number" name="damage_photo_with_case_number" accept="image/*" onChange={handleFileChange} />
              {formErrors.damage_photo_with_case_number && <div className="text-danger">{formErrors.damage_photo_with_case_number}</div>}
            </div>
            <button type="submit" className="btn btn-success">שלח</button>
          </form>
        </div>
      
      </div>
    </div>
  );
}

export default AddNewProject;
