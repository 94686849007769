import React, { useState, useEffect } from 'react';
import { getUserInfo } from '../../api/user';
import { getMonthlySummaries, updateMonthlySummary } from '../../api/monthlySummary'; // Import monthly summary API
import { useTranslation } from 'react-i18next';
import MonthlySummaryTable from '../../components/collection/monthlySummaryTable'; // Import monthly summary table
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

function Home() {
    const { t } = useTranslation();
    const [userInfo, setUserInfo] = useState(null);
    const [monthlySummaries, setMonthlySummaries] = useState([]); // State for monthly summaries
    const [totalIncomeFromSummaries, setTotalIncomeFromSummaries] = useState(0);  // Total bids from monthly summaries
    const [totalExpensesFromSummaries, setTotalExpensesFromSummaries] = useState(0);  // Total expenses from monthly summaries
    const [monthlyIncomeData, setMonthlyIncomeData] = useState([]);  // Data for the chart
    const [monthlyExpensesData, setMonthlyExpensesData] = useState([]);  // Data for the chart

    useEffect(() => {
        fetchUserInfo();
        fetchMonthlySummaries(); // Fetch monthly summaries
    }, []);

    const fetchUserInfo = async () => {
        try {
            const fetchedUserInfo = await getUserInfo();
            setUserInfo(fetchedUserInfo);
        } catch (error) {
            console.error("Error fetching user info:", error);
        }
    };

    const fetchMonthlySummaries = async () => {
        try {
            const fetchedSummaries = await getMonthlySummaries(); // Fetch monthly summaries
            setMonthlySummaries(fetchedSummaries);
            calculateSummaryTotals(fetchedSummaries);
            prepareChartData(fetchedSummaries); // Prepare chart data for income and expenses
        } catch (error) {
            console.error('Error fetching monthly summaries:', error);
        }
    };

    // Calculate totals for the current year from monthly summaries
    const calculateSummaryTotals = (summaries) => {
        const currentYear = new Date().getFullYear();  // Get the current year
        let totalIncome = 0;
        let totalExpenses = 0;

        summaries.forEach(summary => {
            if (summary.year === currentYear) {
                totalIncome += Number(summary.total_income) || 0;
                totalExpenses += Number(summary.total_expenses) || 0;
            }
        });

        setTotalIncomeFromSummaries(totalIncome);
        setTotalExpensesFromSummaries(totalExpenses);
    };

    // Prepare chart data based on monthly summaries
    const prepareChartData = (summaries) => {
        const currentYear = new Date().getFullYear();
        const incomeByMonth = Array(12).fill(0);  // Initialize array for 12 months
        const expensesByMonth = Array(12).fill(0);

        summaries.forEach(summary => {
            if (summary.year === currentYear && summary.month >= 1 && summary.month <= 12) {
                const monthIndex = summary.month - 1;  // JavaScript months are 0-indexed
                incomeByMonth[monthIndex] += Number(summary.total_income) || 0;
                expensesByMonth[monthIndex] += Number(summary.total_expenses) || 0;
            }
        });

        setMonthlyIncomeData(incomeByMonth);
        setMonthlyExpensesData(expensesByMonth);
    };

    const formatNumber = (number) => {
        return new Intl.NumberFormat('en-US').format(number);
    };

    const handleSaveMonthlySummary = async (summaryData) => {
        try {
            await updateMonthlySummary(summaryData);
            toast.success('Monthly summary updated successfully');
            fetchMonthlySummaries(); // Refresh the summary list after saving
        } catch (error) {
            toast.error('Error updating monthly summary');
            console.error('Error updating monthly summary:', error);
        }
    };

    // Chart data for react-chartjs-2
    // const chartData = {
    //     labels: [
    //         'ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 
    //         'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'
    //     ],
    //     datasets: [
    //         {
    //             label: 'סה"כ הכנסות',
    //             data: monthlyIncomeData,
    //             borderColor: 'rgba(75, 192, 192, 1)',
    //             backgroundColor: 'rgba(75, 192, 192, 0.2)',
    //             fill: true,
    //             tension: 0.1,
    //         },
    //         {
    //             label: 'סה"כ הוצאות',
    //             data: monthlyExpensesData,
    //             borderColor: 'rgba(255, 99, 132, 1)',
    //             backgroundColor: 'rgba(255, 99, 132, 0.2)',
    //             fill: true,
    //             tension: 0.1,
    //         },
    //     ],
    // };

    return (
        <div dir="rtl" className="container">
            <div className='pt-3'>
                <h3 className='mt-3 fw-500'>{t('greeting.welcome')}, {userInfo && userInfo.name.split(" ")[0]}!</h3>
            </div>

            <div className="row pt-3">
                <div className="col-md-4">
                    {/* Display total from current year summaries */}
                    <div className="bg-white rounded p-3 mt-3 shadow-sm">
                        <h5>סה"כ הכנסות (תקציר חודשי - שנה נוכחית)</h5>
                        <h6>{formatNumber(totalIncomeFromSummaries)}</h6>
                    </div>
                    <div className="bg-white rounded p-3 mt-3 shadow-sm">
                        <h5>סה"כ הוצאות (תקציר חודשי - שנה נוכחית)</h5>
                        <h6>{formatNumber(totalExpensesFromSummaries)}</h6>
                    </div>
                </div>

                {/* Chart for Total Income and Expenses by Month */}
                {/* <div className="col-md-12">
                    <div className="bg-white rounded p-3 mt-3 shadow-sm">
                        <h5>הכנסות והוצאות לפי חודש (שנה נוכחית)</h5>
                        <Line data={chartData} />
                    </div>
                </div> */}

                {/* New Monthly Summary Table */}
                <div className="col-md-12">
                    <div className="table-responsive bg-white rounded p-3 shadow-sm mt-5">
                        <MonthlySummaryTable
                            summaries={monthlySummaries}
                            onSaveSummary={handleSaveMonthlySummary}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
