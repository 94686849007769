import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { getProjects, updateProjectInfo } from '../api/project'; // Adjust this import if you have a different endpoint
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CaseTable() {
    const columns = [
        { label: 'מזהה', key: 'id', inputType: 'text', hidden: true }, // Set hidden flag if you don't want to display this
        { label: 'מספר תיק', key: 'name', inputType: 'text' },
        { label: 'מספר רישוי אוטובוס', key: 'bus_license_number', inputType: 'text' },
        { label: 'מספר רישוי צד ג', key: 'third_party_license_number', inputType: 'text' },
        { label: 'שם ומספר טלפון צד ג', key: 'third_party_name_and_phone', inputType: 'text' },
        { label: 'Actions', key: 'actions' },
    ];

    const [cases, setCases] = useState([]);
    const [filteredCases, setFilteredCases] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        fetchCases();
    }, []);

    const fetchCases = async () => {
        try {
            const fetchedCases = await getProjects(); // Replace with the correct API function if different
            setCases(fetchedCases);
            setFilteredCases(fetchedCases);
        } catch (error) {
            console.error('Error fetching cases:', error);
        }
    };

    useEffect(() => {
        const results = cases.filter(item =>
            Object.values(item).some(
                value => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setFilteredCases(results);
    }, [searchTerm, cases]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleInputChange = (caseId, key, value) => {
        setCases(prevCases =>
            prevCases.map(item =>
                item.id === caseId ? { ...item, [key]: value } : item
            )
        );
    };

    const handleSave = async (caseItem) => {
        try {
            await updateProjectInfo(caseItem); // Replace with the correct API function if different
            toast.success('Case updated successfully');
            fetchCases(); // Refresh the cases list
        } catch (error) {
            toast.error('Error updating case');
            console.error('Error updating case:', error);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredCases.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredCases.length / itemsPerPage);

    return (
        <div className='bg-white rounded p-3 shadow-sm mt-5'>
            <div>
                <input 
                    type="text" 
                    placeholder="חיפוש" 
                    value={searchTerm} 
                    onChange={handleSearch} 
                    className="form-control my-3" 
                />
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        {columns.map(col => (
                            !col.hidden && <th key={col.key}>{col.label}</th> // Hide column in the header
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((caseItem) => (
                        <tr key={caseItem.id}>
                            {columns.map(col => (
                                col.hidden ? null : (
                                    col.key !== 'actions' ? (
                                        <td key={col.key}>
                                            {col.inputType === 'text' && (
                                                <Form.Control
                                                    type="text"
                                                    value={caseItem[col.key] || ''}
                                                    onChange={(e) => handleInputChange(caseItem.id, col.key, e.target.value)}
                                                />
                                            )}
                                        </td>
                                    ) : (
                                        <td key="actions">
                                            <Button
                                                variant="primary"
                                                onClick={() => handleSave(caseItem)}
                                            >
                                                שמור
                                            </Button>
                                        </td>
                                    )
                                )
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="d-flex flex-column justify-content-between mb-3">
                <div className="col-md-3 ml-1">
                    <label>כמות פריטים להציג</label>
                    <select value={itemsPerPage} onChange={handleItemsPerPageChange} className="form-select">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                    </select>
                </div>
                <label className="mr-2 mt-2">מעבר בין עמודים</label>
                <div className="d-flex flex-row col-md-8 mt-2">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-light'}`}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CaseTable;
