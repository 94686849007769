import React, { useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import logo from '../../assets/img/logo-atia.jpeg'; // Keep the logo import

function CaseForm() {
    const [formData, setFormData] = useState({
        caseNumber: '',
        alternateCarNumber: '',
        borrowDate: '',
        returnDate: '',
        idNumber: '',
        fullName: '',
        address: '',
        city: '',
        carModel: '',
        carNumber: '',
        kilometers: '',
        fuelAmount: '',
        borrowTime: '',
        returnTime: '',
        signature: '',
        idImage: null // New state for ID image
    });

    const sigCanvas = useRef({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setFormData(prevState => ({ ...prevState, idImage: file }));
    };

    const handleClearSignature = () => {
        sigCanvas.current.clear();
        setFormData(prevState => ({ ...prevState, signature: '' }));
    };

    const handleSaveSignature = () => {
        const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        setFormData(prevState => ({ ...prevState, signature }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Submitted Data:", formData);
        // Submit logic goes here (e.g., send data to an API)
    };

    return (
        <div className="bg-white rounded p-5 shadow-sm d-flex flex-column align-items-center justify-content-center" dir="rtl" style={{ minHeight: '100vh' }}>
            {/* Title and Logo */}
            <div className="text-center mb-3">
                <h2>קבלת רכב חליפי והצהרת נהג להסבת דוחות</h2>
                <img src={logo} alt="Logo" style={{ width: '150px' }} />
            </div>

            {/* Form Start */}
            <form onSubmit={handleSubmit} style={{ maxWidth: '600px', width: '100%' }}>

                {/* Instructions */}
                <h4>צד ג' יקר,</h4>
                <p>קיבלת מאת פריים ליס (להלן: "החברה") רכב חליפי. הרינו להסב את תשומת ליבך להנחיות הבאות:</p>
                <ul>
                    <li>הינך נדרש לנהוג ברכב זה בהתאם לחוקי התנועה ובזהירות הנדרשת ולשמור על הרכב כמנהג בעלים.</li>
                    <li>הגבלת מרחק הק"מ ביום הינה 200 ק"מ. במידה וחרגת, תחויב בעלות של 80 אגורות לכל ק"מ נוסף.</li>
                    <li>יש להחזיר את הרכב עם כמות דלק זהה. תחויב בדמי תדלוק בתוספת דמי שירות במידה ולא.</li>
                    <li>עליך לשלם עבור נסיעות בכבישי אגרה. אם התשלום יגיע לאחר החזרת הרכב, יחויב מכרטיס האשראי.</li>
                    <li>במקרה תאונה תחויב ב- 2,000 ₪ (לא כולל מע"מ).</li>
                    <li>אין להעביר את הרכב לנהיגת אדם אחר.</li>
                    <li>אתה אחראי על כל קנסות ועבירות תנועה במהלך השימוש ברכב.</li>
                    <li>נהג חדש/צעיר אינו מורשה לנהוג ברכב זה.</li>
                </ul>

                {/* Driver Declaration Section */}
                <h4>הצהרת נהג</h4>

                <div className="mb-3">
                    <label>אני הח"מ</label>
                    <input
                        type="text"
                        className="form-control"
                        name="fullName"
                        placeholder="שם מלא"
                        value={formData.fullName}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>נושא ת.ז. מס'</label>
                    <input
                        type="text"
                        className="form-control"
                        name="idNumber"
                        placeholder="מספר תעודת זהות"
                        value={formData.idNumber}
                        onChange={handleChange}
                    />
                </div>

                {/* New Input for Uploading ID Image */}
                <div className="mb-3">
                    <label> העלאת תעודת זהות</label>
                    <input
                        type="file"
                        className="form-control"
                        onChange={handleImageUpload}
                        accept="image/*"
                    />
                </div>

                <div className="mb-3">
                    <label>מתגורר ברחוב</label>
                    <input
                        type="text"
                        className="form-control"
                        name="address"
                        placeholder="כתובת"
                        value={formData.address}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>יישוב</label>
                    <input
                        type="text"
                        className="form-control"
                        name="city"
                        placeholder="עיר"
                        value={formData.city}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>קבלת רכב מסוג</label>
                    <input
                        type="text"
                        className="form-control"
                        name="carModel"
                        placeholder="סוג הרכב"
                        value={formData.carModel}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>מספר רכב</label>
                    <input
                        type="text"
                        className="form-control"
                        name="carNumber"
                        placeholder="מספר רכב"
                        value={formData.carNumber}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>ק"מ</label>
                    <input
                        type="text"
                        className="form-control"
                        name="kilometers"
                        placeholder="קילומטרז'"
                        value={formData.kilometers}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>כמות דלק</label>
                    <input
                        type="text"
                        className="form-control"
                        name="fuelAmount"
                        placeholder="כמות דלק"
                        value={formData.fuelAmount}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>תאריך קבלת הרכב</label>
                    <input
                        type="date"
                        className="form-control"
                        name="borrowDate"
                        value={formData.borrowDate}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>שעה</label>
                    <input
                        type="time"
                        className="form-control"
                        name="borrowTime"
                        value={formData.borrowTime}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>תאריך החזרה</label>
                    <input
                        type="date"
                        className="form-control"
                        name="returnDate"
                        value={formData.returnDate}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label>שעה</label>
                    <input
                        type="time"
                        className="form-control"
                        name="returnTime"
                        value={formData.returnTime}
                        onChange={handleChange}
                    />
                </div>

                <h4>הצהרות נוספות</h4>
                <p>4. הנני מצהיר כי קראתי את ההנחיות מעלה והסכמתי להן.</p>
                <p>5. הנני מצהיר כי הרכב הנ"ל נמצא ברשותי המלאה. הנני נוהג בו והנני אחראי לכל עבירות חניה ותנועה למיניהן. כמו כן, הנני אחראי לכל עבירה שנעשתה עם הרכב הנ"ל בתקופה בה החזקתי ברכב ובחתימתי על מסמך זה הנני מאשר לבצע הסבת כל דו"ח או קנס שמועד ביצוע העבירה היה בתקופה בה הרכב היה בחזקתי ובאחריותי.</p>
                <p>6. כאשר החברה תדרוש את הרכב חזרה, אני מתחייב להחזירו תוך 24 שעות ובמידה ולא אני מאשר לחברה לחייב אותי בצורה אוטומטית ב- 160 ₪ בתוספת מע"מ  לכל יום ו/או חלקו ללא כל אישור מקדים ממני.</p>
                <p>7. הריני מאשר בזאת לחייב את כרטיס האשראי לטובת החברה במשך תקופת ההחזקה והשימוש ברכב ובמשך שנה מיום קבלת הרכב החלופי, בכל החיובים מכח קבלת הרכב לרבות ולא רק, נסיעות בכבישי אגרה, דלק, דמי טיפול בקנסות וכיוצ"ב.</p>

                <div className="mb-3">
                    <label>תאריך</label>
                    <input
                        type="date"
                        className="form-control"
                        name="signatureDate"
                        value={formData.signatureDate}
                        onChange={handleChange}
                    />
                </div>

                {/* Digital Signature Section */}
                <div className="mb-3">
                    <label>חתימה דיגיטלית</label>
                    <SignatureCanvas
                        ref={sigCanvas}
                        penColor="black"
                        canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                        onEnd={handleSaveSignature}
                    />
                    <button type="button" className="btn btn-secondary mt-2" onClick={handleClearSignature}>
                        נקה חתימה
                    </button>
                </div>

                {formData.signature && (
                    <div className="mt-3">
                        <img
                            src={formData.signature}
                            alt="Digital Signature"
                            style={{ marginTop: '10px', maxWidth: '200px' }}
                        />
                    </div>
                )}

                <button type="submit" className="btn btn-primary mt-3">שלח</button>
            </form>

            {/* Additional Instructions */}
            <div className="mt-5">
                <p>• הרכב נועד לשמש אותך נאמנה, אנא שמור עליו כראוי.</p>
                <p>• יש לשמור על הניקיון. אין לעשן ברכב, אין לשאת בעלי חיים ברכב.</p>
                <p>• החברה אינה אחראית על גניבת חפצים מתוך הרכב.</p>
                <p>• יש לצרף צילום ת. זהות</p>
                <p>• יש לצרף צילום רישיון נהיגה</p>
                <p>• יש לצלם כ. אשראי (משני צדדיו) לצורך ביטחון</p>
            </div>
        </div>
    );
}

export default CaseForm;
