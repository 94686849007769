import React, { useState, useEffect } from "react";
import profileImagePlaceholder from "../../assets/img/profile.svg";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBars } from "@fortawesome/free-solid-svg-icons";
import NotificationsDropdown from "./NotificationsDropdown";
import UserDropdown from "./UserDropdown";
import { getProjectMembers } from "../../api/project";
import { Link } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';  // Importing Dropdown from React-Bootstrap

const lngs = {
  en: { nativeName: 'English' },
  he: { nativeName: 'Hebrew' }
};

const flagMapping = {
  English: "https://flagicons.lipis.dev/flags/4x3/us.svg",
  Hebrew: "https://flagicons.lipis.dev/flags/4x3/il.svg",
};

function Header({ pageTitle, userInfo, setUserInfo }) {
  const [isNotificationsDropdownOpen, setIsNotificationsDropdownOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [projectMembers, setProjectMembers] = useState([]);
  const { t, i18n } = useTranslation();

  const toggleNotificationsDropdown = () => {
    setIsNotificationsDropdownOpen(!isNotificationsDropdownOpen);
  };

  const toggleUserDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
  };

  const loadProjectInfo = async () => {
    const response = await getProjectMembers();
    setProjectMembers(response.map((response) => response.user));
  };

  useEffect(() => {
    loadProjectInfo();
  }, []);

  return (
    <div className="pb-1 bg-white">
      <nav className="header justify-content-between shadow-sm">
        <div className="d-flex align-items-center">
          <span className="medium fw-500">{pageTitle}</span>
        </div>

        {/* Sidebar Routes Dropdown - Only visible on mobile */}
        <div className="d-md-none px-2 mx-1 d-flex align-items-center">
          <Dropdown>
            <Dropdown.Toggle variant="basic" id="dropdown-basic">
              <FontAwesomeIcon icon={faBars} />
              <span className="ms-2">תפריט</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/home">
                {t('sidebar.home')}
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/projects">
                {t('sidebar.projects')}
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/collection">
                פיננסים
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/salesman">
                תיקים חדשים
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/tasks">
                משימות
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="d-flex align-items-center">
          {/* Notifications */}
          <div className="px-2 mx-1 d-flex flex-column align-items-center justify-content-center">
            <div className="dropdown custom-dropdown">
              <a
                href="#"
                onClick={toggleNotificationsDropdown}
                className="dropdown-link d-flex align-items-center"
                aria-haspopup="true"
                aria-expanded={isNotificationsDropdownOpen}
              >
                <FontAwesomeIcon icon={faBell} className="h4 text-muted m-0 pointer" />
              </a>
              {isNotificationsDropdownOpen && (
                <NotificationsDropdown userInfo={userInfo} setUserInfo={setUserInfo} />
              )}
            </div>
          </div>

          {/* User Profile */}
          <div className="px-2 mx-1 d-flex flex-column align-items-center justify-content-center">
            <div className="dropdown custom-dropdown">
              <a
                href="#"
                onClick={toggleUserDropdown}
                className="dropdown-link d-flex align-items-center"
                aria-haspopup="true"
                aria-expanded={isUserDropdownOpen}
              >
                <img
                  src={userInfo?.profile_image ?? profileImagePlaceholder}
                  className="rounded pointer"
                  alt=""
                  style={{
                    maxHeight: 28,
                    aspectRatio: 1,
                    objectFit: "cover",
                    height: "100%",
                  }}
                />
              </a>

              {isUserDropdownOpen && (
                <UserDropdown userInfo={userInfo} setUserInfo={setUserInfo} />
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
