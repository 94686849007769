import React, { useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

function FormComponent() {
    const [formData, setFormData] = useState({
        caseNumber: '',
        eventDate: '',
        ownerName: '',
        phone: '',
        carNumber: '',
        licenseNumber: '',
        eventDescription: '',
        signature: '',
    });

    const sigCanvas = React.useRef();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleClearSignature = () => {
        sigCanvas.current.clear();
        setFormData(prevState => ({ ...prevState, signature: '' }));
    };

    const handleSaveSignature = () => {
        const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        setFormData(prevState => ({ ...prevState, signature }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data Submitted:", formData);
    };

    return (
        <div className="container" style={{ direction: 'rtl' }}>
            <h3>טופס קבלה/שירות/תיקון</h3>
            <form onSubmit={handleSubmit}>

                <div className="mb-3">
                    <label className="form-label">מספר תיק:</label>
                    <input
                        type="text"
                        className="form-control"
                        name="caseNumber"
                        value={formData.caseNumber}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">תאריך אירוע:</label>
                    <input
                        type="date"
                        className="form-control"
                        name="eventDate"
                        value={formData.eventDate}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">בעל רכב:</label>
                    <input
                        type="text"
                        className="form-control"
                        name="ownerName"
                        value={formData.ownerName}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">מספר טלפון:</label>
                    <input
                        type="text"
                        className="form-control"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">מספר רכב:</label>
                    <input
                        type="text"
                        className="form-control"
                        name="carNumber"
                        value={formData.carNumber}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">מספר רישוי:</label>
                    <input
                        type="text"
                        className="form-control"
                        name="licenseNumber"
                        value={formData.licenseNumber}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">תיאור אירוע:</label>
                    <textarea
                        className="form-control"
                        name="eventDescription"
                        rows="3"
                        value={formData.eventDescription}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">חתימה:</label>
                    <div>
                        <SignatureCanvas
                            ref={sigCanvas}
                            penColor="black"
                            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                            onEnd={handleSaveSignature}
                        />
                        <button type="button" className="btn btn-secondary mt-2" onClick={handleClearSignature}>נקה חתימה</button>
                    </div>
                    {formData.signature && (
                        <img
                            src={formData.signature}
                            alt="Signature"
                            style={{ marginTop: '10px', maxWidth: '100px' }}
                        />
                    )}
                </div>

                <button type="submit" className="btn btn-primary">שלח</button>
            </form>
        </div>
    );
}

export default FormComponent;
