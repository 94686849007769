import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { getProjects } from '../api/project'; // Adjust this import based on your API function
import { useTranslation } from 'react-i18next';

function Leads({ projects, setProjects }) {
    const { t } = useTranslation();

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const fetchedProjects = await getProjects();
                const finishedProjects = fetchedProjects.filter(project => project.status === 'finished' && project.document);
                setProjects(finishedProjects);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchProjects();
    }, [setProjects]);

    const handleDeleteProject = async (project_id) => {
        try {
            // Implement the delete project logic here if needed
            const updatedProjects = projects.filter(project => project.id !== project_id);
            setProjects(updatedProjects);
        } catch (error) {
            console.error('Error deleting project:', error);
        }
    };

    // Demo data
    const demoProjects = [
        {
            id: 1,
            name: 'Project A',
            description: 'Description of Project A',
            document: 'http://example.com/documentA.pdf',
            finished_at: '2023-06-12',
            status: 'finished'
        },
        {
            id: 2,
            name: 'Project B',
            description: 'Description of Project B',
            document: 'http://example.com/documentB.pdf',
            finished_at: '2023-07-22',
            status: 'finished'
        }
    ];

    return (
        <>
            <div className='container'>
                <h2 className='bold ps-2'>תיקים שהסתיימו</h2>

                <div className='m-auto d-block w-100' style={{ maxWidth: 1500, overflowX: 'auto' }}>
                    <div className='table-responsive'>
                        <table className='table table-hover'>
                            <thead>
                                <tr>
                                    <th className='text-secondary bg-transparent fw-500'>ID</th>
                                    <th className='text-secondary bg-transparent fw-500'>Name</th>
                                    <th className='text-secondary bg-transparent fw-500'>Description</th>
                                    <th className='text-secondary bg-transparent fw-500'>Document</th>
                                    <th className='text-secondary bg-transparent fw-500'>Date Finished</th>
                                    <th className='text-secondary bg-transparent fw-500'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {demoProjects.map(project => (
                                    <tr key={project.id}>
                                        <td className='bg-transparent'>{project.id}</td>
                                        <td className='bg-transparent'>{project.name}</td>
                                        <td className='bg-transparent'>{project.description}</td>
                                        <td className='bg-transparent'>
                                            <a href={project.document} target="_blank" rel="noopener noreferrer">
                                                View Document
                                            </a>
                                        </td>
                                        <td className='bg-transparent'>{new Date(project.finished_at).toLocaleDateString()}</td>
                                        <td className='bg-transparent'>
                                            <div className="h-100 d-flex align-items-center justify-content-center">
                                                <button className='btn btn-basic bg-gray text-danger shadow-sm' onClick={() => handleDeleteProject(project.id)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Leads;
